import React, { useEffect, useState } from "react";
import "./MainDoctor.scss";
import Datatable from "../../../components/datatable/Datatable";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import Navbar from "../../../components/Navbar/Navbar";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import { useDispatch, useSelector } from "react-redux";
import { fetchDoctors } from "../../../store/doctorsSlice";

function MainDoctor() {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch doctors data on component mount
  useEffect(() => {
    dispatch(fetchDoctors({ lang: i18n.language }));
  }, [dispatch, i18n.language]);

  // Retrieve data from Redux store
  const { doctors, loading, errorAll } = useSelector(
    (state) => state.persistData.doctors
  );
  console.log("loading", loading);
  console.log("errorAll", errorAll);
  console.log("doctorsdoctors", doctors);
  const userColumns = [
    { field: "id", headerName: "ID", width: 60, sortable: "desc" },
    {
      field: "first_name",
      headerName: "Doctor",
      width: 200,
      renderCell: (params) => (
        <div className="cellWithImg">
          {params.row.user_picture ? (
            <img
              className="cellImg"
              src={`${"BASE_URL"}/storage/${params.row.user_picture}`}
              alt="avatar"
            />
          ) : (
            <div className="boxImageChar">
              <span>{params.row.first_name.slice(0, 1)}</span>
            </div>
          )}
          {params.row.first_name + " " + params.row.last_name}
        </div>
      ),
    },
    { field: "gender", headerName: "Gender", sortable: false, width: 100 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      sortable: false,
      width: 160,
    },
    { field: "location", headerName: "Address", sortable: false, width: 130 },
    {
      field: "location_details",
      headerName: "Address Two",
      sortable: false,
      width: 200,
    },
    {
      field: "birthday",
      headerName: "Date Of Birth",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <div className="remaining">{params.row.birthday.split(" ")[0]}</div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <div className="action-icon">
          <ModalMulti params={params} filter="doctors" type="view" />
          <EditIcon
            onClick={() => gotonewfn(params.id, "edit")}
            className="icon-edit"
          />
          <ModalDelete filter="doctors" params={params} />
        </div>
      ),
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const gotonewfn = (id, name) => {
    if (name === "add") navigate("/doctor/add");
    else if (name === "edit") navigate(`/doctor/edit/${id}`);
    else if (name === "show") navigate(`/doctor/${id}`);
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={doctors}
          selectedRowIds={selectedRowIds}
          title="Doctors"
          filter="Doctors"
          link="/doctor/add"
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={doctors || []}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainDoctor;
