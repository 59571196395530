import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./adminhome.scss";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Link } from "react-router-dom";
import Recharts from "../../components/recharts/Recharts";
import RenderBarChart from "../../components/renderBarChart/renderBarChart";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function AdminHome() {
  const [t, i18n] = useTranslation();

  let dispatch = useDispatch();
  useEffect(() => {
    // dispatch(GetAllDashboradDate({ lang: i18n.language }));
  }, []);

  // let {
  //   data: datadashborad,
  //   loading,
  //   error,
  // } = useSelector((state) => state.dashborad);

  const certificate_chart = {
    January: 312,
    December: 22,
    November: 140,
    October: 20,
    September: 100,
    August: 200,
  };
  // Add more data as needed
  let account = {
    January: 1,
    December: 49,
    November: 10,
    October: 29,
    September: 20,
    August: 30,
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="home admin-home">
        <div className="row">
          <div className="col-md-3">
            <Link to="/users" className="box-number">
              <div className="box-icon user">
                <PersonIcon />
              </div>
              <div className="box-title">
                <h6>{"Total Users"}</h6>
                <h4>{158}</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/companies" className="box-number">
              <div className="box-icon company">
                <AccountBalanceIcon />
              </div>
              <div className="box-title">
                <h6>{"Total Company"}</h6>
                <h4>{54}</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/main-course" className="box-number ">
              <div className="box-icon course">
                <AttachMoneyIcon />
              </div>
              <div className="box-title">
                <h6>{"Total Price"}</h6>
                <h4>{35000 + "$"}</h4>
              </div>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/users" className="box-number">
              <div className="box-icon Instructor">
                <SpatialAudioOffIcon />
              </div>
              <div className="box-title">
                <h6>{"Total Admin"}</h6>
                <h4>{20}</h4>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-2 cloumn">
            <div className="title mb-2">{"Last 5 Months (Users)"}</div>
            <RenderBarChart data={account} aspect={2 / 1} />
          </div>
          <div className="col-md-12 mt-2">
            <Recharts
              title="Last 6 Months (Money)"
              aspect={2 / 1}
              data={certificate_chart}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
