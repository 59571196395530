import React from "react";
import "./renderBarChart.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

function RenderBarChart({ data, aspect }) {
  const chartData = Object.keys(data)
    .reverse()
    .map((month) => ({
      name: month,
      user: data[month],
    }));

  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart width={chartData.length * 60} height={250} data={chartData}>
        {/* Adjust the width based on the number of data points */}
        <XAxis dataKey="name" stroke="#233a80" />
        <YAxis />
        <Tooltip />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        {/* Adjust the barSize based on your preference */}
        <Bar dataKey="user" fill="#233a80" barSize={20} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default RenderBarChart;
