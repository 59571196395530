import React from "react";
import "./ModalDelete.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";
import DeletePopUpUser from "../../pages/Folder-User/DeletePopUpUser/DeletePopUpUser";
import DeletePopUpDoctor from "../../pages/Folder-Doctors/DeletePopUpDoctor/DeletePopUpDoctor";

function ModalDelete({ params: data, type, selectedRowIds, filter }) {
  const [t, i18n] = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <div onClick={handleOpen} className="d-flex gap-2 align-items-center">
        {<DeleteIcon />}
        {type === "all" ? t("Delete Selected") : ""}
      </div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        {filter === "users" ? (
          <DeletePopUpUser
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : filter === "doctors" ? (
          <DeletePopUpDoctor
            type={type}
            handleClose={handleClose}
            selectedRowIds={selectedRowIds}
            data={data}
          />
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
}

export default ModalDelete;
