import * as React from "react";
import "./DoctrorTimePickerView.scss";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

export default function DoctorTimePickerView({
  getdataAppo,
  type,
  index,
  value,
  name,
  title,
  SingleDoctor,
  dataSingleAppo,
  SelDate,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["TimePicker"]}>
        <TimePicker
          label={
            name === "edit"
              ? `previous value : ${value}`
              : title
              ? title
              : "Choose Time, please"
          }
          onChange={(newDate) => {
            const formattedTime = newDate.format("HH:mm");
            getdataAppo(formattedTime, type, index);
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          ampm={false}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
