import React, { useEffect, useState } from "react";
import "./MainUser.scss";
import Datatable from "../../../components/datatable/Datatable";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import Navbar from "../../../components/Navbar/Navbar";
import EditIcon from "@mui/icons-material/Edit";
import Actions from "../../../components/Actions/Actions";
import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import { useSelector } from "react-redux";
import {
  getAllpatient,
  resetsingleDatapatients,
} from "../../../store/patientslice";
import { useDispatch } from "react-redux";

function MainUser() {
  const [t, i18n] = useTranslation();
  const label = { inputProps: { "aria-label": "Size switch demo" } };

  let navigation = useNavigate();
  let gotonewfn = (id, name) => {
    if (name === "add") {
      navigation("/users/add");
    }
    if (name === "edit") {
      navigation(`/users/edit/${id}`);
    }
    if (name === "show") {
      navigation(`/users/${id}`);
    }
  };

  const userColumns = [
    { field: "id", headerName: "ID", width: 60, sortable: "desc" },
    {
      field: "name",
      headerName: "Patient",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.image ? (
              <img
                className="cellImg"
                src={`${"BASE_URL"}/storage/${params.row.image}`}
                alt="avatar"
              />
            ) : (
              <div className="boxImageChar">
                <span> {params?.row?.name?.slice(0, 1)}</span>
              </div>
            )}

            {params?.row?.name}
          </div>
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      sortable: false,
      width: 130,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      sortable: false,
      width: 200,
    },
    {
      field: "address",
      headerName: "Address",
      sortable: false,
      width: 170,
    },
    // {
    //   field: "clinic_knowledge",
    //   headerName: "Heard About Us from",
    //   sortable: false,
    //   width: 200,
    // },
    {
      field: "birthday",
      headerName: "Date Of Birth",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        return <div className="remaining">{params.row.date_of_birthday}</div>;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <div className="action-icon">
            <div className=" list-icons ">
              <div className="icon-view">
                <ModalMulti params={params} filter="users" type="viwe" />
              </div>
              <div className="icon-edit">
                <EditIcon
                  onClick={(e) => gotonewfn(params.id, "edit")}
                  className="icon-edit"
                />
              </div>
              <div className="icon-delete">
                <ModalDelete filter="users" params={params} />
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  // Function to handle selection change
  const handleSelectionChange = (selectionModel) => {
    // Store the selected row IDs in state
    setSelectedRowIds(selectionModel);
    // Log the selected row IDs to the console
    console.log("Selected Row IDs:", selectionModel);
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllpatient({ lang: i18n.language }));
    dispatch(resetsingleDatapatients(""));
  }, []);

  let {
    data: users,
    loading,
    errorAll,
  } = useSelector((state) => state.persistData.patients);

  console.log("usersusers", users);
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={users}
          selectedRowIds={selectedRowIds}
          title="Patients"
          filter="users"
          link="/users/add"
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={users && users}
              onSelectionModelChange={handleSelectionChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default MainUser;
