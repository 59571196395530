import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HeaderComponent from "../../../components/HeaderComponents/HeaderComponent";
import Navbar from "../../../components/Navbar/Navbar";
import AddIcon from "@mui/icons-material/Add";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AvilableTime from "../../../components/AvilableTime/AvilableTime";

function EditCreateDoctor() {
  let dispatch = useDispatch();
  let { name: type, id } = useParams();
  const [t, i18n] = useTranslation();
  let [file, setfile] = useState(null);
  let [inp, setInp] = useState("");

  let availableDays = [
    "saturday",
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
  ];

  const [selectedDays, setSelectedDays] = useState([]);

  const handleDaySelection = (selectedDay, index) => {
    setSelectedDays((prevSelectedDays) => {
      // Find the index of the selectedDay in the array
      const existingIndex = prevSelectedDays.findIndex(
        (day) => day.index === index
      );

      // If the selectedDay already exists in the array, update its value
      if (existingIndex !== -1) {
        const updatedSelectedDays = [...prevSelectedDays];
        updatedSelectedDays[existingIndex] = { day: selectedDay, index };
        return updatedSelectedDays;
      } else {
        // If the selectedDay is not in the array, add it
        return [...prevSelectedDays, { day: selectedDay, index }];
      }
    });
  };

  let [DataAppo, setDataAppo] = useState([{}]);

  let getdataAppo = (e, type, index) => {
    setDataAppo((prev) => {
      const newDataAppo = [...prev];
      let currentItem = newDataAppo[index] || {};

      if (type === "start") {
        currentItem = { ...currentItem, start_time: e };
      } else if (type === "end") {
        currentItem = { ...currentItem, end_time: e };
      } else {
        currentItem = { ...currentItem, [e.target.name]: e.target.value };
        handleDaySelection(e.target.value, index);
      }

      newDataAppo[index] = currentItem;
      return newDataAppo;
    });
  };

  let [number, setNumber] = useState(type === "edit" ? "" : [1]);

  const handleShowMore = () => {
    if (number.length < 7) {
      setNumber([...number, 2]);
    }
  };

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  let sendData = (e) => {
    e.preventDefault();
  };

  let singleData = false;

  const path = useNavigate();
  let handleClose = (e) => {
    path(-1);
  };
  let loadingBTN = false;

  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <HeaderComponent title={"Add Doctor"} />
      <div className="two-box">
        <form className="Details row" onSubmit={(e) => sendData(e)}>
          <div className="col-lg-3 col-sm-6 row">
            <div
              className="col-lg-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <div className="det-inp">
                <label htmlFor="file">
                  <img
                    className="b6e5"
                    src={
                      "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                    alt="..."
                  />
                  <CloudUploadIcon className="icon ms-2" />
                </label>
                <input
                  type="file"
                  id="file"
                  name="up"
                  placeholder="name"
                  style={{ display: "none" }}
                />
              </div>

              <div className="form-group d-flex gap-2 justify-content-center align-items-center w-100 me-5">
                <p>
                  {t("Status")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  defaultChecked={
                    type === "edit" && singleData && singleData.status == 1
                      ? true
                      : false
                  }
                  onChange={(event) => handleSwitchChange(event, "status")}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-sm-6 row">
            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"email"}
                  type={"email"}
                  label={t("Email")}
                  name={"email"}
                  required
                  defaultValue={
                    type === "edit" ? singleData && singleData.email : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            {type === "edit" && (
              <div className="col-lg-4 col-sm-6">
                <div className="form-group mb-4 mt-4">
                  <TextField
                    id={"password"}
                    type={"password"}
                    label={t("Password")}
                    name={"password"}
                    required
                    defaultValue={
                      type === "edit" ? singleData && singleData.password : ""
                    }
                    onChange={(e) => getValue(e)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("First Name")}
                  name={"first_name"}
                  required
                  defaultValue={
                    type === "edit" ? singleData && singleData.first_name : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Last Name")}
                  name={"last_name"}
                  required
                  defaultValue={
                    type === "edit" ? singleData && singleData.last_name : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="Gender">{t("Gender")}</InputLabel>
                  <Select
                    labelId="Gender"
                    name="gender"
                    id="Role"
                    defaultValue={
                      type === "edit" && singleData ? singleData.role[0] : ""
                    }
                    required
                    label={"Role"}
                    onChange={(e) => getValue(e)}
                  >
                    <MenuItem value={"male"}>male</MenuItem>
                    <MenuItem value={"female"}>female</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"number"}
                  label={t("Phone Number")}
                  name={"phone"}
                  required
                  defaultValue={
                    type === "edit" ? singleData && singleData.phone : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="Competence type">
                    {t("Competence type")}
                  </InputLabel>
                  <Select
                    labelId="competence_type"
                    name="competence_type"
                    id="competence_type"
                    defaultValue={
                      type === "edit" && singleData
                        ? singleData.competence_type
                        : ""
                    }
                    required
                    label={"Competence type"}
                    onChange={(e) => getValue(e)}
                  >
                    <MenuItem value={"general_dentistry"}>
                      General dentistry
                    </MenuItem>
                    <MenuItem value={"orthodontics"}>Orthodontics</MenuItem>
                    <MenuItem value={"periodontology_and_oral_surgery"}>
                      Periodontology and oral surgery
                    </MenuItem>
                    <MenuItem value={"cosmetic_dentistry"}>
                      Cosmetic dentistry
                    </MenuItem>
                    <MenuItem value={"childrens_dentistry"}>
                      Childrens dentistry
                    </MenuItem>
                    <MenuItem value={"neurology_in_dentistry"}>
                      Neurology in dentistry
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Address")}
                  name={"location"}
                  required
                  defaultValue={
                    type === "edit" ? singleData && singleData.location : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"number"}
                  label={t("Years of experience")}
                  name={"years_of_experience"}
                  required
                  defaultValue={
                    type === "edit"
                      ? singleData && singleData.years_of_experience
                      : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Address 2")}
                  name={"location_details"}
                  required
                  defaultValue={
                    type === "edit"
                      ? singleData && singleData.location_details
                      : ""
                  }
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <InputLabel id="Date Of Birth">
                  {t("Date Of Birth")}:
                </InputLabel>
                <DatePicker />
              </LocalizationProvider>
            </div>
          </div>

          <div className=" row">
            <hr className="mt-5" />

            <div className="col-sm-4">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth>
                  <InputLabel id="availability_Time">
                    {t("Availability Time")}
                  </InputLabel>
                  <Select
                    labelId="availability_type"
                    name="availability_type"
                    id="availability_type"
                    defaultValue={
                      type === "edit" && singleData
                        ? singleData.availability_type
                        : ""
                    }
                    required
                    label={"Availability Time"}
                    onChange={(e) => getValue(e)}
                  >
                    <MenuItem value={"full_time"}>Full time</MenuItem>
                    <MenuItem value={"part_time"}>Part time</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="avilable col-lg-12  ">
              {type === "edit" ? (
                <Fragment>
                  {number &&
                    number.map((avail, index) => (
                      <AvilableTime
                        key={index}
                        days={availableDays}
                        getdataAppo={getdataAppo}
                        index={index}
                        selectedDays={selectedDays}
                        availability={avail} // Pass the availability data
                        name={type}
                        // Pass the function as a prop
                      />
                    ))}
                  <div className="box-icon" onClick={handleShowMore}>
                    <AddIcon className="icon-add" />
                  </div>
                </Fragment>
              ) : (
                inp.availability_type === "part_time" && (
                  <Fragment>
                    {number &&
                      number.map((avail, index) => (
                        <AvilableTime
                          key={index}
                          days={availableDays}
                          getdataAppo={getdataAppo}
                          index={index}
                          selectedDays={selectedDays}
                          availability={avail} // Pass the availability data
                          name={type}
                          // Pass the function as a prop
                        />
                      ))}
                    <div className="box-icon" onClick={handleShowMore}>
                      <AddIcon className="icon-add" />
                    </div>
                  </Fragment>
                )
              )}
            </div>
          </div>
          <div
            className="btn-lest mt-5"
            style={{ margin: "40px !important", justifyContent: "center" }}
          >
            {loadingBTN ? (
              <button type="text" disabled className="btn btn-primary-rgba ">
                <CheckCircleIcon color="#fff" /> {t("Loading")}...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary-rgba">
                <CheckCircleIcon color="#fff" />
                {type === "edit" ? t("Edit") : t("Craete")}
              </button>
            )}

            <span onClick={handleClose} className="btn btn-danger-rgba">
              <DoNotDisturbAltIcon color="#fff" /> {t("Back")}
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCreateDoctor;
