import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useClickOutside from "../../useClickOutside";
import SettingsIcon from "@mui/icons-material/Settings";

function CheckMultiLangu({ toggleLanguage, selectedLanguages }) {
  const [t, i18n] = useTranslation();

  const availableLanguages = Object.keys(i18n.options.resources);

  let [toggleLangu, setToggleLangu] = useState(false);

  let togglefn = () => {
    setToggleLangu(!toggleLangu);
  };
  let domNode = useClickOutside(() => {
    setToggleLangu(false);
  });

  return (
    <div className="multi-icon" ref={domNode}>
      <SettingsIcon className="icon" onClick={togglefn} />
      {
        <div className={toggleLangu ? "show-check" : "d-none"}>
          {availableLanguages.map((lang) => (
            <span key={lang}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedLanguages.includes(lang)}
                onChange={() => toggleLanguage(lang)}
              />
              {lang}
            </span>
          ))}
        </div>
      }
    </div>
  );
}

export default CheckMultiLangu;
