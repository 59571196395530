const { createSlice } = require("@reduxjs/toolkit");

let themeslice = createSlice({
  name: "theme",
  initialState: {
    dark: false,
    langu: "",
  },
  reducers: {
    toggle: (state, action) => {
      state.dark = !state.dark;
    },
    clickble: (state, action) => {
      state.dark = action.payload;
    },
    langu: (state, action) => {
      state.langu = action.payload;
    },
  },
});

export default themeslice.reducer;
export let { toggle, clickble, langu } = themeslice.actions;
