import React, { useEffect, useState } from "react";
import DoctorTimePickerView from "../DoctorTimePicker/DoctrorTimePickerView";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function AvilableTime({
  getdataAppo,
  index,
  days,
  selectedDays,
  handleDaySelection,
  availability,
  name,
}) {
  let [newday, setnewday] = useState(days);
  // Initially set the state to the availability data received as a prop
  let [selectedDay, setSelectedDay] = useState(
    name === "edit" ? availability.day_of_week : "chose value"
  );
  let [start_time, setStartTime] = useState(
    name === "edit" ? availability.start_time : ""
  );
  let [end_time, setEndTime] = useState(
    name === "edit" ? availability.end_time : ""
  );

  const remainingDays = days.filter((day) => {
    // Check if any item in selectedDays has the same 'day'
    return !selectedDays.some((selectedDay) => selectedDay.day === day);
  });
  useEffect(() => {
    setnewday(remainingDays);
  }, []);

  return (
    <div className="avilable-part row">
      {/*  <div className="col-lg-4 col-sm-6">
        <div className="det-inp">
          <label>
            Day <small>*</small> :{" "}
          </label>
          <select
            style={{
              width: "100%",
            }}
            name="day_of_week"
            defaultValue={
              typeof availability === "object" ? selectedDay : "select one"
            } //
            className="form-select"
            required
            onChange={(e) => {
              getdataAppo(e, "inp", index);
            }}
          >
            <option
              disabled
              value={
                typeof availability === "object" ? selectedDay : "select one"
              }
            >
              {typeof availability === "object" ? selectedDay : "select one"}
            </option>

            {newday.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
        </div>
            </div>*/}

      <div className="col-lg-4 col-sm-6">
        <div
          className="form-group mb-4 margin-import"
          style={{ marginTop: "2.1rem !important" }}
        >
          <FormControl fullWidth>
            <InputLabel id="Day">{"Day"}</InputLabel>
            <Select
              labelId="Day"
              name="day_of_week"
              id="day_of_week"
              defaultValue={typeof availability === "object" ? selectedDay : ""}
              required
              label={"Day"}
              onChange={(e) => {
                getdataAppo(e, "inp", index);
              }}
            >
              {newday.map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="col-lg-4 col-sm-6">
        <DoctorTimePickerView
          getdataAppo={getdataAppo}
          type="start"
          title="Start Time"
          index={index}
          value={start_time}
          name={typeof availability === "object" ? "edit" : "add"}
        />
      </div>
      <div className="col-lg-4 col-sm-6">
        <DoctorTimePickerView
          getdataAppo={getdataAppo}
          type="end"
          title="End Time"
          index={index}
          value={end_time}
          name={typeof availability === "object" ? "edit" : "add"}
        />
      </div>
    </div>
  );
}

export default AvilableTime;
