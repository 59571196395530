import React from "react";
import ModalDelete from "../ModalDelete/ModalDelete";
import ModalMulti from "../ModalMulti/ModalMulti";
import ExportFunctions from "../ExportFunctions/ExportFunctions";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";

function HeaderPage({ data, selectedRowIds, title, filter, link }) {
  const [t, i18n] = useTranslation();
  return (
    <div className="main-header">
      <h3>{t(title)}</h3>
      <div className="btn-list">
        <ExportFunctions data={data} title={title} />
        <button className="btn">
          {link ? (
            <Link
              style={{ color: "#fff" }}
              to={link}
              className={`d-flex gap-2 align-items-center ${title}`}
            >
              <AddIcon /> Add New
            </Link>
          ) : (
            <ModalMulti
              data={data}
              filter={filter}
              type="add"
              title={"Add New"}
            />
          )}
        </button>
        <button className="btn btn-delete">
          <ModalDelete
            filter={filter}
            params={data}
            type="all"
            selectedRowIds={selectedRowIds}
          />
        </button>
      </div>
    </div>
  );
}

export default HeaderPage;
