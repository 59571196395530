import { combineReducers, configureStore } from "@reduxjs/toolkit";
import patients from "./patientslice";
import doctors from "./doctorsSlice";

import auth from "./authslice";
import themeslice from "./theme";
import commonSlice from "./commonSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

// Create a persistConfig for the auth slice
const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["data"], // Specify the properties you want to persist
};

const patientsPersistConfig = {
  key: "patients",
  storage: storage,
};
const DoctorsPersistConfig = {
  key: "doctors",
  storage: storage,
};

const customReducer = combineReducers({
  themeslice: themeslice,
  auth: persistReducer(authPersistConfig, auth),
  patients: persistReducer(patientsPersistConfig, patients),
  Commone: persistReducer(patientsPersistConfig, commonSlice),
  doctors: persistReducer(DoctorsPersistConfig, doctors),
  // Add other reducers as needed
});

const persistCustomReducer = persistReducer(persistConfig, customReducer);

let store = configureStore({
  reducer: {
    persistData: persistCustomReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);

export default store;
