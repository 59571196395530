import axios from "axios";
import { BASE_URL } from "../apiConfig"; // Adjust the path based on your project structure
import { notifyError, notifysuccess } from "../Notification";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export let createPatient = createAsyncThunk(
  "patient/createPatient",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/cors/patients`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllpatient = createAsyncThunk(
  "patient/getAllpatient",
  async (arg, ThunkAPI) => {
    console.log("asdads");
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/cors/patients`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      console.log("data", data.data.result.patients.data);
      return data.data.result.patients.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deletepatient = createAsyncThunk(
  "patient/deletepatient",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/cors/patients/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSinglePatient = createAsyncThunk(
  "patient/getSinglePatient",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/cors/patients/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      console.log("data.result", data);
      return data.data.result.item;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let updatePAtient = createAsyncThunk(
  "patient/updatePAtient",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;

    try {
      let data = await axios.post(
        `${BASE_URL}/cors/patients/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let patientslice = createSlice({
  name: "patient",
  initialState: {
    loadingroles: false,
    data: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    roles: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    errorAll: false,
    errorSingle: false,
  },
  reducers: {
    resetAlluserAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleDatapatients: (state, action) => {
      state.singleData = action.payload;
      state.dataCreate = action.payload;
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //createPatient

    [createPatient.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [createPatient.fulfilled]: (state, action) => {
      state.error = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.message);
      state.loadingBTN = false;
    },
    [createPatient.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //updatePAtient

    [updatePAtient.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [updatePAtient.fulfilled]: (state, action) => {
      state.loadingBTN = false;
      state.dataUpdate = action.payload;
      state.error = false;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [updatePAtient.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getSinglePatient

    [getSinglePatient.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSinglePatient.fulfilled]: (state, action) => {
      state.singleData = action.payload;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSinglePatient.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //deletepatient

    [deletepatient.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deletepatient.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [deletepatient.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllpatient

    [getAllpatient.pending]: (state, action) => {
      state.loading = true;
      state.singleData = "";
      state.dataCreate = false;
      state.dataUpdate = false;
      state.errorAll = false;
      state.data = false;
    },
    [getAllpatient.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.errorAll = false;
      state.loading = false;
    },
    [getAllpatient.rejected]: (state, action) => {
      state.loading = false;
      state.errorAll = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default patientslice.reducer;
export let { resetAlluserAction, resetsingleDatapatients } =
  patientslice.actions;
