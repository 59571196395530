import React from "react";
import "./NotFound.scss";
import ErrorIcon from "../../assits/page-not-found.svg"; // Import the SVG file
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="error-page">
      <img src={ErrorIcon} alt="Error Icon" />
      <p>Page Not Found</p>
      <Link className="btn btn-primary" to="/">
        Go To Home
      </Link>
    </div>
  );
}

export default NotFound;
