import React from "react";
import "./LoadingScreen.scss";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <div className="wrapper">
        <div className="box-wrap">
          <div className="box one" />
          <div className="box two" />
          <div className="box three" />
          <div className="box four" />
          <div className="box five" />
          <div className="box six" />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
