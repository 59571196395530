import React from "react";

function HeaderComponent({ title, children }) {
  return (
    <div
      className="header-component"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h2>{title}</h2>
      {children}
    </div>
  );
}

export default HeaderComponent;
