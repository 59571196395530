import React, { useState } from "react";
import "./ExportFunctions.scss";
import * as XLSX from "xlsx";
import SettingsIcon from "@mui/icons-material/Settings";
import useClickOutside from "../../useClickOutside";

function ExportFunctions({ data, title }) {
  let [togglePrint, setTogglePrint] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  // Add a function to handle XLSX export

  const handleExportXLSX = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, `${title} Data`);
    XLSX.writeFile(wb, `${title}.xlsx`);
  };

  // Function to generate CSV data
  const generateCSV = () => {
    const columns = Object.keys(data[0]);

    // Generate header row
    let csvData = columns.join(",") + "\n";

    // Generate data rows
    data.forEach((row) => {
      const rowData = columns.map((col) => row[col]).join(",");
      csvData += rowData + "\n";
    });

    return csvData;
  };

  // Add a function to handle CSV export
  const handleExportCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + encodeURI(generateCSV());

    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", `${title}.csv`);
    link.click();
  };

  // Add a function to handle copying data to clipboard
  const handleCopyToClipboard = () => {
    const columns = Object.keys(data[0]);
    const rows = data.map((row) => columns.map((col) => row[col]));

    const copyText = rows.map((row) => row.join("\t")).join("\n");

    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        console.log("Data copied to clipboard");
        setIsCopied(true); // Set the state to show the success message
        setTimeout(() => {
          setIsCopied(false); // Hide the success message after a delay
        }, 3000); // Hide after 3 seconds
      })
      .catch((error) => {
        console.error("Failed to copy data:", error);
      });
  };

  let domNode = useClickOutside(() => {
    setTogglePrint(false);
  });

  return (
    <div
      onClick={() => setTogglePrint(!togglePrint)}
      className="icon-list-print"
      ref={domNode}
    >
      <SettingsIcon />
      <div className={togglePrint ? "show-print" : "d-none"}>
        <span onClick={handleCopyToClipboard}>Copy</span>
        <span onClick={handleExportCSV}>CSV</span>
        <span onClick={handleExportXLSX}>Excel</span>
      </div>
    </div>
  );
}

export default ExportFunctions;
