import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export const getAllGenderList = createAsyncThunk(
  "common/getAllGenderList",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/core/common/list/genders`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.result.list;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllChronicDiseases = createAsyncThunk(
  "common/getAllChronicDiseases",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const data = await axios.get(
        `${BASE_URL}/core/common/list/chronic-diseases`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      return data.data.result.list;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllAccessMethod = createAsyncThunk(
  "common/getAllAccessMethod",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const data = await axios.get(
        `${BASE_URL}/core/common/list/access-methods`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      return data.data.result.list;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllLanguageSpeaks = createAsyncThunk(
  "common/getAllLanguageSpeaks",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const data = await axios.get(
        `${BASE_URL}/core/common/list/language-speaks`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      return data.data.result.list;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let commonSlice = createSlice({
  name: "common",
  initialState: {
    genders: false,
    ChronicD: false,
    dataAccses: false,
    dataLanguSpk: false,
    loadingGen: false,
    loadingChronic: false,
    loadingAccses: false,
    loadingLanguSpk: false,
    errorGen: false,
    errorChronic: false,
    errorAllAccses: false,
    errorAllLanguSpk: false,
  },
  reducers: {
    showsliderfn: (state, action) => {
      state.showslider = !state.showslider;
    },
    islogged: (state, action) => {
      state.logged = action.payload;
    },
  },
  extraReducers: {
    //getAllGenderList

    [getAllGenderList.pending]: (state, action) => {
      state.loadinggend = true;
      state.errorGen = false;
    },
    [getAllGenderList.fulfilled]: (state, action) => {
      state.genders = action.payload;
      state.errorGen = false;
      state.loadingGen = false;
    },
    [getAllGenderList.rejected]: (state, action) => {
      state.loadingGen = false;
      state.errorGen = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllChronicDiseases

    [getAllChronicDiseases.pending]: (state, action) => {
      state.loadingCronic = true;
      state.errorChronic = false;
    },
    [getAllChronicDiseases.fulfilled]: (state, action) => {
      state.ChronicD = action.payload;
      state.errorChronic = false;
      state.loadingChronic = false;
    },
    [getAllChronicDiseases.rejected]: (state, action) => {
      state.loadingChronic = false;
      state.errorChronic = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllAccessMethod

    [getAllAccessMethod.pending]: (state, action) => {
      state.loadingAccses = true;
      state.errorAllAccses = false;
      state.dataAccses = false;
    },
    [getAllAccessMethod.fulfilled]: (state, action) => {
      state.dataAccses = action.payload;
      state.errorAllAccses = false;
      state.loadingAccses = false;
    },
    [getAllAccessMethod.rejected]: (state, action) => {
      state.loadingAccses = false;
      state.errorAllAccses = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllLanguageSpeaks

    [getAllLanguageSpeaks.pending]: (state, action) => {
      state.loadingLanguSpk = true;
      state.errorAllLanguSpk = false;
      state.dataLanguSpk = false;
    },
    [getAllLanguageSpeaks.fulfilled]: (state, action) => {
      state.dataLanguSpk = action.payload;
      state.errorAllLanguSpk = false;
      state.loadingLanguSpk = false;
    },
    [getAllLanguageSpeaks.rejected]: (state, action) => {
      state.loadingLanguSpk = false;
      state.errorAllLanguSpk = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default commonSlice.reducer;

export let { showsliderfn, islogged } = commonSlice.actions;
