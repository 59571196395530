// routes.js
import { Navigate } from "react-router-dom";
import Login from "./pages/login/Login";
import AdminHome from "./pages/AdminHome/AdminHome";
import NotFound from "./pages/NotFoundPage/NotFound";
import MainUser from "./pages/Folder-User/MainUser/MainUser";
import EditCreateUser from "./pages/Folder-User/EditCreateUser/EditCreateUser";
import MainDoctor from "./pages/Folder-Doctors/MainDoctor/MainDoctor";
import EditCreateDoctor from "./pages/Folder-Doctors/EditCreateDoctor/EditCreateDoctor";

const useRout = (token, sidebarOpen) => {
  let test = true;
  console.log("token", token);
  const routes = [
    { path: "/", element: token ? <AdminHome /> : <Login /> },
    {
      path: "/login",
      element: token ? <Navigate to="/" replace /> : <Login />,
    },
    {
      path: "users",
      element: <MainUser />,
    },
    {
      path: "users/:name",
      element: <EditCreateUser />,
    },
    {
      path: "users/:name/:id",
      element: <EditCreateUser />,
    },
    {
      path: "doctors",
      element: <MainDoctor />,
    },
    {
      path: "doctor/:name",
      element: <EditCreateDoctor />,
    },
    {
      path: "doctor/:name/:id",
      element: <EditCreateDoctor />,
    },

    { path: "*", element: <NotFound /> },
  ];
  return routes;
};

export default useRout;
