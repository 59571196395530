// src/slices/doctorsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { notifyError, notifysuccess } from "../Notification";

const API_URL = "/cors/doctors";

// Async Thunks for doctor operations with headers and ThunkAPI for token and notifications
export const fetchDoctors = createAsyncThunk(
  "doctors/fetchDoctors",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.get(`${BASE_URL}${API_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return response.data.result.doctors.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchDoctorById = createAsyncThunk(
  "doctors/fetchDoctorById",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.get(`${BASE_URL}${API_URL}/${arg.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const createDoctor = createAsyncThunk(
  "doctors/createDoctor",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(`${BASE_URL}${API_URL}`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const updateDoctor = createAsyncThunk(
  "doctors/updateDoctor",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.put(
        `${BASE_URL}${API_URL}/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteDoctor = createAsyncThunk(
  "doctors/deleteDoctor",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id];
      const promises = idList.map((id) =>
        axios.delete(`${BASE_URL}${API_URL}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );
      const responses = await Promise.all(promises);
      notifysuccess(responses[0].data.message);
      return idList;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const doctorsSlice = createSlice({
  name: "doctors",
  initialState: {
    doctors: [],
    doctor: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all doctors
      .addCase(fetchDoctors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDoctors.fulfilled, (state, action) => {
        state.loading = false;
        console.log("plqpwlpqwldpqw", action.payload);
        state.doctors = action.payload;
      })
      .addCase(fetchDoctors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch doctor by ID
      .addCase(fetchDoctorById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDoctorById.fulfilled, (state, action) => {
        state.loading = false;
        state.doctor = action.payload;
      })
      .addCase(fetchDoctorById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create a new doctor
      .addCase(createDoctor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.doctors.push(action.payload);
      })
      .addCase(createDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update a doctor
      .addCase(updateDoctor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDoctor.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.doctors.findIndex(
          (doctor) => doctor.id === action.payload.id
        );
        if (index !== -1) {
          state.doctors[index] = action.payload;
        }
      })
      .addCase(updateDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a doctor
      .addCase(deleteDoctor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.doctors = state.doctors.filter(
          (doctor) => !action.payload.includes(doctor.id)
        );
      })
      .addCase(deleteDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default doctorsSlice.reducer;
