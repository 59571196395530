import React from "react";
import "./ErrorCompo.scss";
import ErrorIcon from "../../assits/not-fount-farmy.svg"; // Import the SVG file

function ErrorCompo() {
  return (
    <div>
      <div className="error-compo ">
        <img src={ErrorIcon} alt="Error Icon" />
        <p>Something Went Wrong</p>
        <button
          className="btn btn-primary"
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
      </div>
    </div>
  );
}

export default ErrorCompo;
