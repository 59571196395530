import React from "react";
import "./recharts.scss";
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function Recharts({ title, aspect, data }) {
  // Extracting the chart data from the provided data object and reversing the order
  const chartData = Object.keys(data)
    .reverse() // Reverse the order of the months
    .map((month) => ({
      name: month,
      Total: data[month],
    }));

  return (
    <div className="rechart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={chartData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#233a80" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#233a80" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartgrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#233a80"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Recharts;
