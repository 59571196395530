import React, { useEffect } from "react";
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";

function Datatable({ userColumns, userRows, onSelectionModelChange }) {
  const getRowClassName = (params, index) => {
    return params.row.id % 2 === 0 ? "even-row" : "odd-row";
  };

  return (
    <div className="datatable">
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          className="datatable"
          rows={userRows && userRows}
          columns={userColumns}
          checkboxSelection
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 100]} // Options for the dropdown
          getRowClassName={getRowClassName}
          disableRowSelectionOnClick={true}
          onRowSelectionModelChange={(e) => onSelectionModelChange(e)}
        />
      </div>
    </div>
  );
}

export default Datatable;
